import "styles/pages/page-thank-you.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import { Link } from "gatsby";

const ThankYouPage = () => {
  return (
    <Layout headerColor="#FFCE2E">
      <Seo title="Wiadomośc została wysłana" />
      <SubpageHeader
        image={require("assets/images/thank-you-bg-main.jpg").default}
      />
      <section className="page-thank-you">
        <div className="container">
          <div className="page-thank-you__content">
            <h2 className="page-thank-you__title">Wiadomość została wysłana</h2>
            <div className="page-thank-you__text">
              <strong> Dziękujemy za skontaktowanie się z nami.</strong> <br />
              <br />
              Zrobimy wszystko co w naszej mocy, aby szybko i sprawnie udzielić
              Ci odpowiedzi.
            </div>
            <Link to="/" className="button">
              Strona główna
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ThankYouPage;
